export const defaults = {
    page_title: "Кухаркин в СОЧИ по кайфу - Телеграм.SITES",
    main_url: "https://tracker.moldova-telegram.com/tracking202/redirect/go.php?acip=915",
    name: "Сочи по кайфу с Пашей Кухаркиным",
    tag: "@sochipokaifu",
    main_avatar_url: "/img/sochi.jpg",
    parsers:[
        {
            name: "Сочи по Кайфу",
            parser: "SOCHI",
            tag: "@sochipokaifu",
            main_url: "https://tracker.sochi-telegram.com/tracking202/redirect/go.php?acip=113",
            avatar_url: "/img/sochi.jpg",
            contacts_phrase: "Непрочитанные сообщения",
            new_messages: 7
        },
        {
            name: "Павел Кухаркин",
            parser: "PASHA_TODAY",
            tag: "@pashatoday",
            main_url: "https://tracker.sochi-telegram.com/tracking202/redirect/go.php?acip=736",
            avatar_url: "/img/pashatoday.jpg",
            contacts_phrase: "Выслал тебе в ЛС... Сочи каеф",
            new_messages: 6
        },
    ],
    posts: []
}
